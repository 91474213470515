import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HomeBanner from '../components/HomeBanner';
import CompanyCards from '../components/CompanyCards';
import NewsCards from '../components/NewsCards';
import CallToActions from '../components/CallToActions';
import SocialLinks from '../components/SocialLinks';
import Testimonial from '../components/Testimonial';
import ClimateClock from '../components/ClimateClock';

const IndexPage = ({
  data: {
    contentfulHome: {
      title,
      banner,
      companyCards,
      companiesText: { companiesText },
      investedCompaniesOverline,
      investedCompanyCards,
      investedCompaniesText: { investedCompaniesText },
      contactText,
      newsHeading,
      callToActions,
      faceBookUrl,
      linkedInUrl,
      twitterUrl,
      testimonial,
    },
    allContentfulNewsResource: { nodes },
  },
}) => {
  return (
    <Layout>
      <main>
        <HomeBanner title={title} items={banner} isHome={true} />
        <CompanyCards items={companyCards} text={companiesText} />
        <CompanyCards
          items={investedCompanyCards}
          text={investedCompaniesText}
          contactText={contactText}
          overline={investedCompaniesOverline}
        />
        <NewsCards heading={newsHeading} items={nodes} />
        <CallToActions items={callToActions} />
        <SocialLinks
          faceBookUrl={faceBookUrl}
          linkedInUrl={linkedInUrl}
          twitterUrl={twitterUrl}
        />
        <Testimonial items={testimonial} />
        <ClimateClock />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query PageQuery {
    contentfulHome {
      title
      metaDescription
      banner {
        heading
        image {
          gatsbyImageData(width: 984, height: 1154, quality: 100)
          title
        }
        text {
          raw
        }
      }
      companiesText {
        companiesText
      }
      contactText
      companyCards {
        icons {
          energy
          travel
          food
          environment
        }
        image {
          mimeType
          height
          title
          url
          width
        }
        url
        text {
          text
        }
      }
      investedCompaniesOverline
      investedCompaniesText {
        investedCompaniesText
      }
      investedCompanyCards {
        icons {
          energy
          travel
          food
          environment
        }
        image {
          mimeType
          height
          title
          url
          width
        }
        url
        text {
          text
        }
      }
      newsHeading
      callToActions {
        heading
        text
        buttonText
        url
        image {
          gatsbyImageData(width: 241, height: 270)
          title
        }
      }
      faceBookUrl
      linkedInUrl
      twitterUrl
      testimonial {
        author
        quote {
          quote
        }
      }
    }
    allContentfulNewsResource(sort: { fields: date, order: DESC }, limit: 4) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(width: 800, height: 528)
          title
        }
        pdf {
          title
          url
        }
        excerpt
        content {
          raw
        }
        metaDescription
      }
    }
  }
`;

export default IndexPage;

export const Head = ({
  data: {
    contentfulHome: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
