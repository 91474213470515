import React from 'react';
import styled from 'styled-components';
import {
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  minBreakpointQuery,
} from '../styles';
import { Container } from './ui';

const StyledTestimonial = styled.section`
  margin: 90px 0;
`;

const StyledContainer = styled(Container)`
  max-width: 1380px;
`;

const StyledQuote = styled.blockquote`
  ${fluidFontSize(
    '30px',
    '46px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  font-family: ${brandFonts.tertiary};
  line-height: 1.6;
`;

const StyledAuthor = styled.figcaption`
  ${fontSize(20)}
  font-family: ${brandFonts.secondary};
  letter-spacing: 4px;
  text-transform: uppercase;

  ${minBreakpointQuery.medium`
    ${fontSize(24)}
  `}
`;

const Testimonial = ({
  items: {
    author,
    quote: { quote },
  },
}) => (
  <StyledTestimonial>
    <StyledContainer>
      <StyledQuote>
        {quote}
        <StyledAuthor>{' - ' + author}</StyledAuthor>
      </StyledQuote>
    </StyledContainer>
  </StyledTestimonial>
);

export default Testimonial;
