import React from 'react';
import { Script } from 'gatsby';
import styled from 'styled-components';
import { Container } from './ui';

const StyledClimateClock = styled.section`
  margin-top: 80px;
`;

const StyledContainer = styled(Container)`
  max-width: 1980px;
  padding: 0;
`;

const ClimateClock = () => (
  <StyledClimateClock>
    <StyledContainer>
      <Script src="https://climateclock.world/widget-v2.js" />
      <climate-clock />
    </StyledContainer>
  </StyledClimateClock>
);

export default ClimateClock;
