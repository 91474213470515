import React from 'react';
import styled, { css } from 'styled-components';
import {
  brandColours,
  brandFonts,
  fontSize,
  minBreakpointQuery,
} from '../styles';
import Card from './Card';
import { Button, Container, Svg } from './ui';
import energyIcon from '../images/energy-icon.inline.svg';
import foodIcon from '../images/food-icon.inline.svg';
import travelIcon from '../images/travel-icon.inline.svg';
import environmentIcon from '../images/environment-icon.inline.svg';

const StyledCompanyCard = styled.section``;

const StyledInner = styled.div`
  display: grid;
  gap: 50px;
  margin: auto;

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.xlarge`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${minBreakpointQuery.xxxlarge`
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  `}

  ${({ $hasContact }) => {
    if ($hasContact) {
      return css`
        padding-bottom: 170px;
        border-bottom: solid ${brandColours.quaternary} 26px;
      `;
    } else {
      return css`
        padding-bottom: 80px;
      `;
    }
  }}
`;

const StyledCard = styled(Card)`
  ${({ active }) => {
    if (!active) {
      return css`
        opacity: 30%;
      `;
    }
  }}
`;

const StyledIconWrapper = styled.div`
  margin-top: 30px;
`;

const StyledIcon = styled(Svg)`
  background-color: ${brandColours.primary};
  padding: 10px;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  margin-right: 6px;

  ${({ isActive }) => {
    if (!isActive) {
      return css`
        opacity: 20%;
      `;
    }
  }}
`;

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${fontSize(18)}
  gap: 30px;
  min-height: 650px;
  text-align: center;
  align-items: center;
  p {
    max-width: 274px;
    font-family: ${brandFonts.secondary};
  }
`;

const StyledButton = styled(Button)`
  max-width: 220px;
  ${fontSize(18)}
  padding: 24px 62px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const CompanyCard = ({ items, contactText }) => {
  return (
    <StyledCompanyCard>
      <Container>
        <StyledInner $hasContact={contactText}>
          {items.map(
            (
              {
                image,
                url,
                text,
                icons: { energy, travel, food, environment },
              },
              i
            ) => (
              <StyledCard
                image={image}
                url={url}
                text={text}
                isCompanyCard={true}
                linkTag={'Learn More'}
                active={url}
                key={i}
              >
                <StyledIconWrapper>
                  <StyledIcon
                    image={energyIcon}
                    isActive={energy}
                    aria-label={'Energy Icon'}
                  />
                  <StyledIcon
                    image={travelIcon}
                    isActive={travel}
                    aria-label={'Travel Icon'}
                  />
                  <StyledIcon
                    image={foodIcon}
                    isActive={food}
                    aria-label={'Food Icon'}
                  />
                  <StyledIcon
                    image={environmentIcon}
                    isActive={environment}
                    aria-label={'Environment Icon'}
                  />
                </StyledIconWrapper>
              </StyledCard>
            )
          )}
          {contactText && (
            <StyledFooter>
              <p>{contactText}</p>
              <StyledButton href="/contact/">Contact Us</StyledButton>
            </StyledFooter>
          )}
        </StyledInner>
      </Container>
    </StyledCompanyCard>
  );
};

export default CompanyCard;
