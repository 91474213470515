import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Container, Svg } from './ui';
import facebookLogo from '../images/facebook-logo.inline.svg';
import linkedInLogo from '../images/linkedIn-logo.inline.svg';
import twitterLogo from '../images/twitter-logo.inline.svg';

const StyledSocialLinks = styled.section``;

const StyledInner = styled.div`
  margin-bottom: 70px;
  display: flex;
  gap: 25px;
  justify-content: center;

  ${minBreakpointQuery.tiny`
    gap:50px;
  `}
`;

const StyledLink = styled.a``;

const StyledLogo = styled(Svg)``;

const SocialLinks = ({ faceBookUrl, linkedInUrl, twitterUrl }) => (
  <StyledSocialLinks>
    <Container>
      <StyledInner>
        <StyledLink href={faceBookUrl} target="_blank" rel="noopener">
          <StyledLogo image={facebookLogo} aria-label={'Facebook Logo'} />
        </StyledLink>
        <StyledLink href={linkedInUrl} target="_blank" rel="noopener">
          <StyledLogo image={linkedInLogo} aria-label={'LinkedIn Logo'} />
        </StyledLink>
        <StyledLink href={twitterUrl} target="_blank" rel="noopener">
          <StyledLogo image={twitterLogo} aria-label={'Twitter Logo'} />
        </StyledLink>
      </StyledInner>
    </Container>
  </StyledSocialLinks>
);

export default SocialLinks;
