import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  breakpointSizes,
  fontSize,
  fontWeights,
  fluidFontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  zIndexLayers,
} from '../styles';
import { Button, Container, HtmlContent, Svg } from './ui';
import Breadcrumbs from './Breadcrumbs';
import arrowBottom from '../images/arrow-bottom.svg';
import backgroundFlag from '../images/background-flag.inline.svg';

const StyledHomeBanner = styled.div`
  position: relative;

  ${maxBreakpointQuery.large`
    overflow: hidden;
  `}
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: none;
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${minBreakpointQuery.large`
    flex-direction: row;
  `}
`;

const StyledTextWrapper = styled.div`
  z-index: 1;

  margin-top: 68px;
  position: relative;

  ${maxBreakpointQuery.tiny`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.large`
    margin-top: -50px;
    margin-bottom: 62px;
  `}

  ${minBreakpointQuery.xxxlarge`
    margin-top: -100px;
    margin-bottom: 0;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-left: 60px;
  `}
`;

const StyledHeading = styled.h1`
  color: ${brandColours.septenary};

  ${fontSize(26)}
  margin-bottom: 50px;
  font-weight: ${fontWeights.regular};
  ${minBreakpointQuery.small`
    max-width: 100%;
  `}

  ${minBreakpointQuery.large`
    max-width: 380px;
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.xlarge`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.xxxlarge`
    margin-bottom: 50px;
  `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  color: ${brandColours.septenary};
  font-family: ${brandFonts.tertiary};
  p {
    ${fluidFontSize(
      '42px',
      '100px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
    line-height: 1;
  }
  b {
    margin-left: 40px;

    ${minBreakpointQuery.tiny`
      margin-left: 0;
    `}

    ${minBreakpointQuery.large`
      margin-left: 80px;
    `}
  }

  ${minBreakpointQuery.small`
    max-width: 100%;
  `}

  ${minBreakpointQuery.large`
    max-width: 750px;
    min-width: 580px;
  `}

  ${minBreakpointQuery.xlarge`
    min-width: 620px;
  `}

  ${minBreakpointQuery.xxlarge`
    min-width: 680px;
  `}
`;

const StyledButton = styled(Button)`
  scale: 0.7;
  padding: 29px 38px;
  border-radius: 50%;
  border: solid 2px ${standardColours.black};
  background-color: ${standardColours.white};
  margin-top: 20px;
  z-index: ${zIndexLayers.first};

  &:before {
    content: '';
    display: block;
    background: url(${arrowBottom}) no-repeat;
    height: 36px;
    width: 18px;
  }

  ${minBreakpointQuery.medium`  
    margin-left: 50px;
  `}

  ${minBreakpointQuery.large`
    position: absolute;
    bottom: -20px;
    right: -20px;
    margin: 0;
  `}

  ${minBreakpointQuery.xlarge`  
    position: relative;
    scale: 1;
    margin-left: 84px;
    margin-top: 30px;
    bottom: 0px;
    right: 0px;
  `}
`;

const StyledImageWrapper = styled.div`
  position: relative;
`;

const StyledImage = styled(GatsbyImage)`
  position: relative;
  z-index: 1;
  pointer-events: none;
  top: -60px;
`;

const StyledBackgroundFlag = styled(Svg)`
  position: absolute;
  height: auto;
  top: 0;
  right: -60px;
  width: 130%;

  ${minBreakpointQuery.large`
    right: -40px;
  `}
`;

const HomeBanner = ({ title, items: { heading, image, text } }) => {
  const handleClickScroll = target => {
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledHomeBanner>
      <Container wide={true}>
        <StyledBreadcrumbs currentPageTitle={title} />
        <StyledInner>
          <StyledTextWrapper>
            <StyledHeading>{heading}</StyledHeading>
            <StyledHtmlContent richText={text} />
            <StyledButton onClick={() => handleClickScroll('Home')} />
          </StyledTextWrapper>
          <StyledImageWrapper>
            <StyledImage
              image={image.gatsbyImageData}
              alt={image.title}
              objectFit={'contain'}
            />
            <StyledBackgroundFlag
              image={backgroundFlag}
              alt={'Background Flag'}
            />
          </StyledImageWrapper>
        </StyledInner>
      </Container>
    </StyledHomeBanner>
  );
};

export default HomeBanner;
