import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
} from '../styles';
import { Button, Container } from './ui';

const StyledCallToActions = styled.section``;

const StyledOutter = styled.div`
  display: grid;
  justify-content: center;
  gap: 100px;
  margin-bottom: 70px;
  border-top: solid ${brandColours.quaternary} 26px;
  border-bottom: solid ${brandColours.quaternary} 6px;
  padding-top: 100px;
  padding-bottom: 70px;

  ${minBreakpointQuery.xlarge`
    grid-template-columns: repeat(2, 1fr);
    gap:80px;
  `}
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minBreakpointQuery.small`
    flex-direction: row;
    max-width: 560px;
  `}

  &:first-of-type {
    margin-left: auto;
  }

  &:last-of-type {
    margin-right: auto;
  }
`;

const StyledImage = styled(GatsbyImage)`
  flex-shrink: 0;

  ${maxBreakpointQuery.small`
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.small`
     margin-right: 44px;
  `}
`;

const StyledContent = styled.div`
  ${maxBreakpointQuery.small`
    max-width: 278px;
    text-align: center;
  `}
`;

const StyledHeading = styled.h2`
  ${fontSize(28)}
  font-family: ${brandFonts.tertiary};
`;

const StyledText = styled.p`
  ${fontSize(28)};
  font-family: ${brandFonts.secondary};
`;

const StyledButton = styled(Button)`
  max-width: 216px;
  width: 100%;
  border-radius: 100px;
  padding: 23px 62px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 36px;
`;

const CallToActions = ({ items }) => {
  return (
    <StyledCallToActions>
      <Container>
        <StyledOutter>
          {items.map(({ heading, text, buttonText, url, image }, i) => (
            <StyledInner key={i}>
              <StyledImage image={image.gatsbyImageData} alt={image.title} />
              <StyledContent>
                <StyledHeading>{heading}</StyledHeading>
                {text && <StyledText>{text}</StyledText>}
                <StyledButton href={url} target="_blank" rel="noopener">
                  {buttonText}
                </StyledButton>
              </StyledContent>
            </StyledInner>
          ))}
        </StyledOutter>
      </Container>
    </StyledCallToActions>
  );
};

export default CallToActions;
